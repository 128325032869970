import React, { createContext, useContext, useEffect, useState } from 'react';
import authenticationService from './authentication.service';

const noop = function () {};

const AuthContext = createContext({ user: localStorage.getItem('user') || null, login: noop, logout: noop });

const AuthenticationProvider = (props) => {
  const [user, setUser] = useState(localStorage.getItem('user') || null);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('user') ? true : false);

  const login = (userLogged) => {
    setUser(userLogged);
    setIsAuthenticated(true);
  };

  const logout = () => {
    authenticationService.signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const authUser = authenticationService.getAuthUser();
    if (authUser !== null) {
      setUser(authUser);
      setIsAuthenticated(true);
    }
  }, [user]);

  return <AuthContext.Provider value={{ user, login, logout, isAuthenticated }} {...props} />;
};

export default AuthenticationProvider;

export const useAuthentication = () => useContext(AuthContext);
