import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthentication } from '../services/AuthenticationProvider';
import LoginPage from '../views/Users/Login';

export function PrivateRoute({ component, ...options }) {
  const { isAuthenticated } = useAuthentication();
  return isAuthenticated ? <Route {...options} component={component} /> : <LoginPage />;
}

export default PrivateRoute;
