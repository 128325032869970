import Toast from 'atoms/Toast/Toast';
import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import AuthenticationProvider from './services/AuthenticationProvider';
import PrivateRoute from './views/PrivateRoute';

const loading = () => <div className="animated fadeIn pt-3 text-center">Cargando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Users/Login'));
const Register = React.lazy(() => import('./views/Users/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Toast />
        <AuthenticationProvider>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login" render={(props) => <Login {...props} />} />
              <Route exact path="/register" name="Registro" render={(props) => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <PrivateRoute path="/" name="Dashboard" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </AuthenticationProvider>
      </BrowserRouter>
    );
  }
}

export default App;
