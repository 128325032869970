import React from 'react';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './index.css';
import './polyfill';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

console.log(`${process.env.REACT_APP_WEBSITE_NAME} v${process.env.REACT_APP_VERSION} - 20211019`);

serviceWorker.unregister();
