import React from 'react';
export function getApiURL() {
  return process.env.REACT_APP_MY_VARIABLE_API_URL;
}
export function sortTableHelper() {
  return {
    sort: true,
    sortCaret: (order, column) => {
      if (!order) {
        return (
          <>
            <i className="fa fa-caret-up font-16 ml-3"></i>
            <i className="fa fa-caret-down font-16 ml-1"></i>
          </>
        );
      } else if (order === 'asc') {
        return (
          <>
            <i className="fa fa-caret-up font-16 font-primary ml-3 "></i>
            <i className="fa fa-caret-down font-16 ml-1"></i>
          </>
        );
      } else if (order === 'desc') {
        return (
          <>
            <i className="fa fa-caret-up font-16 ml-3"></i>
            <i className="fa fa-caret-down font-primary font-16 ml-1"></i>
          </>
        );
      }
      return null;
    },
  };
}
export function encodeQuery(data) {
  let query = '';
  let value = '';
  let idSearch = false;
  for (let d in data) {
    if (d === 'id') {
      idSearch = true;
      query = data[d] + '?' + (query !== '' ? query + '&' : '');
    } else {
      value = Array.isArray(data[d]) ? data[d].map((n, index) => `${d}[${index}]=${n}`).join('&') : encodeURIComponent(d) + '=' + encodeURIComponent(data[d]);
      query += value + '&';
    }
  }
  if (idSearch) {
    return query && query.slice && query.slice(0, -1);
  } else {
    return '?' + (query && query.slice(0, -1));
  }
}
